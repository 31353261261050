export default defineNuxtRouteMiddleware(async (to) => {
	if (import.meta.server) return
	await useUserSession().fetch()
	const { loggedIn } = useUserSession()
	if (loggedIn.value) {
		if (to.path === '/login' && loggedIn.value) {
			return navigateTo('/')
		}
		return
	} else {
		if (to.path !== '/login') {
			return navigateTo('/login')
			// return navigateTo('/login')
		}
		return
	}
})
